import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Home from "./components/home/Home";
import Baas from "./components/Login/Baas";
import Spinner from "./components/Common/Loading/Spinner";
import AuthContext from "./Store/auth-context";
// import { ToastContainer, toast } from "react-toastify";

import LandingPage from "./components/ondc/LandingPage/LandingPage";
import Authentication from "./components/ondc/Authentication/Authentication";
import OndcRegistration from "./components/ondc/Authentication/Registration/OndcRegistration";
import OndcLogin from "./components/ondc/Authentication/Login/OndcLogin";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { getPartnerProfileDetail } from "./components/Services/UserService";
import { toast, ToastContainer } from "react-toastify";
import { partnerDetails } from "./util/utils";
import PrivateRoute from "./layouts/PrivateRoute";
import { useSelector } from "react-redux";

//Screens
const Login = React.lazy(() =>
  import("./components/Login/CredentialsFolder/Login")
);
const Registration = React.lazy(() =>
  import("./components/Registration/Registration")
);
const ResetPass = React.lazy(() =>
  import("./components/Login/CredentialsFolder/ResetPass/ResetPass")
);
const ForgetPassword = React.lazy(() =>
  import("./components/Login/CredentialsFolder/ForgetPassword/ForgetPassword")
);
const Profile = React.lazy(() =>
  import("./components/home/pages/profile/Profile")
);
const Contact = React.lazy(() =>
  import("./components/home/pages/profile/contactUs/ContactUs")
);
const ChangePassword = React.lazy(() =>
  import("./components/home/pages/profile/ChangePassword/ChangePassword")
);
const Otp = React.lazy(() =>
  import("./components/Login/CredentialsFolder/Otp/otp")
);

//Pages
const HomePage = React.lazy(() =>
  import("./components/home/pages/HomePage/HomePage")
);
const Summary = React.lazy(() => import("./components/Common/Summary/Summary"));
const Transaction = React.lazy(() =>
  import("./components/home/pages/payIn/Transaction/Transaction")
);
const Wallet = React.lazy(() =>
  import("./components/home/pages/payOut/Wallet/Wallet")
);
const Beneficiary = React.lazy(() =>
  import("./components/home/pages/payOut/Beneficiaries/Beneficiary")
);
const OutTransaction = React.lazy(() =>
  import("./components/home/pages/payOut/OutTransaction/Transaction")
);
const VendorWallet = React.lazy(() =>
  import("./components/home/pages/vendor/Wallet/wallet")
);
const OrderHistory = React.lazy(() =>
  import("./components/Common/OrderHistory/OrderHistory")
);
const TransferStatus = React.lazy(() =>
  import("./components/home/pages/status/TransferStatus/TransferStatus")
);
const BeneStatus = React.lazy(() =>
  import("./components/home/pages/status/BeneStatus/BeneStatus")
);
const DetailBulk = React.lazy(() =>
  import("./components/Common/DetailBulk/DetailBulk")
);
const BulkUploadDetails = React.lazy(() =>
  import("./components/Common/DetailBulk/BulkUploadDetails")
);
const StepLayout = React.lazy(() => import("./layouts/StepLayout"));

// Dashboard
const Dashboard = React.lazy(() =>
  import("./components/home/pages/ondc/dashboard/Dashboard")
);

//Product
const AddProduct = React.lazy(() =>
  import("./components/home/pages/Product/AddProducts/AddProduct")
);
const ProductList = React.lazy(() =>
  import("./components/home/pages/Product/ProductList/ProductList")
);
const ProductDetails = React.lazy(() =>
  import("./components/home/pages/Product/ProductDetails/ProductDetails")
);

//null-variant-products
const NullVariantProductList = React.lazy(() =>
  import(
    "./components/home/pages/Product/NullVariantProductList/NullVariantProductList"
  )
);

//Order
const OrderList = React.lazy(() =>
  import("./components/home/pages/Order/OrderList/OrderList")
);
const OrderDetails = React.lazy(() =>
  import("./components/home/pages/Order/OrderDetails/OrderDetails")
);
const OrderCancel = React.lazy(() =>
  import("./components/home/pages/Order/OrderCancel/OrderCancel")
);
const ShipmentsList = React.lazy(() =>
  import("./components/home/pages/Order/Shipments/ShipmentsList")
);

//Bulk Invoice
const BulkInvoice = React.lazy(() =>
  import("./components/home/pages/Order/BulkInvoice/BulkInvoiceList")
);

//Offer
const OfferList = React.lazy(() =>
  import("./components/home/pages/Offer/OfferList/OfferList")
);
const OfferDetails = React.lazy(() =>
  import("./components/home/pages/Offer/OfferDetails/OfferDetails")
);
const AddOffer = React.lazy(() =>
  import("./components/home/pages/Offer/AddOffer/AddOffer")
);
const ItemBased = React.lazy(() =>
  import("./components/home/pages/Offer/ItemBased/ItemBased")
);
const Freebie = React.lazy(() =>
  import("./components/home/pages/Offer/Freebie/Freebie")
);

// Return Order
const ReturnOrderList = React.lazy(() =>
  import("./components/home/pages/Return/ReturnOrderList/ReturnOrderList")
);
const ReturnOrderDetails = React.lazy(() =>
  import("./components/home/pages/Return/ReturnOrderDetails/ReturnOrderDetails")
);
// const ReturnOrderCancel = React.lazy(() => import('./components/home/pages/Order/OrderCancel/OrderCancel'))

//Issue
const IssueList = React.lazy(() =>
  import("./components/home/pages/Issue/IssueList/IssueList")
);
const IssueDetails = React.lazy(() =>
  import("./components/home/pages/Issue/IssueDetails/IssueDetails")
);

// Profile
const SellerProfile = React.lazy(() =>
  import("./components/home/pages/profile/SellerProfile")
);

// Customer
const CustomerList = React.lazy(() =>
  import("./components/home/pages/customer/customerList/CustomerList")
);

// Buyer
const BuyerList = React.lazy(() =>
  import("./components/home/pages/Buyer/BuyerList/BuyerList")
);

// LSP
const LogisticPartnerList = React.lazy(() =>
  import(
    "./components/home/pages/LogisticPartners/LogisticPartnerList/LogisticPartnerList"
  )
);

const Payments = React.lazy(() =>
  import("./components/home/pages/payments/Payments")
);

//MultiStores
const MultiStores = React.lazy(() =>
  import("./components/home/pages/multiStores/MultiStores")
);

//MultiUsers
const MultiUsers = React.lazy(() =>
  import("./components/home/pages/MultiUsers/MultiUsers")
);
const AddMultiUsers = React.lazy(() =>
  import("./components/home/pages/MultiUsers/AddMultiUsers")
);

//Downloads
const Downloads = React.lazy(() =>
  import("./components/home/pages/Report/Downloads")
);

function setFavicon(imageUrl) {
  const head = document.querySelector("head");
  const favicon = document.createElement("link");
  favicon.rel = "shortcut icon";
  favicon.type = "image/x-icon";
  favicon.href = imageUrl;
  head.appendChild(favicon);
}

function App() {
  const toastId = useRef(null);
  const [locationPath, setLocationPath] = useState("");
  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const { pathname } = useLocation();
  const currentPath = useSelector((state) => state.pathname.previousPath);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // const toastId = React.useRef(null);

  // const logoutHandler = (userName) => {
  //   // const authCtx = useContext(AuthContext);
  //   sessionStorage.clear(userName);
  //   localStorage.removeItem('')
  //   if (localStorage.getItem("theme") === "dark") {
  //     document.body.classList.remove("dark-theme");
  //     localStorage.setItem("theme", "light");
  //   }
  //   authCtx.logOut();
  //   document.title="EasyPay";
  // };

  // function runEveryThreeSeconds() {
  //   const userName = sessionStorage.getItem('userName');
  //   const verifyToken = localStorage.getItem(userName);
  //   if(isLoggedIn) {
  //     if(!verifyToken) {
  //       logoutHandler(userName);
  //       window.location.reload();
  //       // if(! toast.isActive(toastId.current)) {
  //       //   toastId.current = toast.error("some one try to login");
  //       // }
  //     }
  //   }
  // }

  // setInterval(runEveryThreeSeconds, 3000);

  const getUrlDetails = async (e) => {
    setLoading(true);
    let payload = {
      host: window.location.href,
    };

    await getPartnerProfileDetail(payload)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          const formResponse = response.data;
          if (
            formResponse.RESP_CODE === 300 &&
            Object.keys(formResponse.DATA).length
          ) {
            sessionStorage.setItem(
              "partnerDetails",
              JSON.stringify(formResponse.DATA)
            );
            document.title = formResponse.DATA.partnerName;
            setFavicon(formResponse.DATA.partnerFaviconLogoPath);
            setLoading(false);
          } else {
            let part = partnerDetails();
            document.title = part.partnerName;
            setFavicon(part.partnerFaviconLogoPath);
            setLoading(false);
          }
        } else {
          setLoading(false);
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error(
              "Server error. Please contact to administrator"
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error(error);
        }
      });
  };

  useEffect(() => {
    getUrlDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route
              path=""
              element={
                <Navigate
                  replace
                  to={isLoggedIn ? currentPath || "seller/dashboard" : "baas"}
                />
              }
            />
            {isLoggedIn && (
              <Route path="" element={<Home />}>
                <Route path="/seller/kyc" element={<StepLayout />} />
                <Route path="/seller/store-info" element={<StepLayout />} />
              </Route>
            )}
            {isLoggedIn && (
              <Route path="" element={<Home />}>
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<HomePage />} />
                  <Route
                    path="/payIn/summary"
                    element={<Summary value={"payIn"} />}
                  />
                  <Route
                    path="/payOut/summary"
                    element={<Summary value={"payOut"} />}
                  />
                  <Route path="/payIn/transaction" element={<Transaction />} />
                  <Route path="/payOut/beneficiary" element={<Beneficiary />} />
                  <Route path="/payOut/wallet" element={<Wallet />} />
                  <Route
                    path="/payOut/transaction"
                    element={<OutTransaction />}
                  />
                  <Route path="/status/transfer" element={<TransferStatus />} />
                  <Route
                    path="/status/transfer/:transferId"
                    element={<DetailBulk />}
                  />
                  <Route
                    path="/status/details/:transferId"
                    element={<BulkUploadDetails />}
                  />
                  <Route path="/status/beneficiary" element={<BeneStatus />} />
                  <Route path="/change-password" element={<ChangePassword />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/vendor/ledger" element={<VendorWallet />} />
                  <Route path="/order/:orderId" element={<OrderHistory />} />
                  {/* Dashboard */}
                  <Route path="/seller/dashboard" element={<Dashboard />} />

                  {/* kyc */}
                  {/* <Route path="/seller/kyc" element={<StepLayout />} />
                  <Route path="/seller/store-info" element={<StepLayout />} /> */}

                  {/* Product */}
                  <Route
                    path="/seller/add-product"
                    element={<AddProduct key={"add-product"} />}
                  />
                  <Route
                    path="/seller/edit-product/:productId"
                    element={<AddProduct key={"edit-product"} />}
                  />
                  <Route
                    path="/seller/copy-product/:productId"
                    element={<AddProduct key={"copy-product"} />}
                  />
                  <Route
                    path="/seller/product-list"
                    element={<ProductList />}
                  />
                  <Route
                    path="/seller/product-list/:productId"
                    element={<ProductDetails />}
                  />

                  {/* NullVariantProductList */}
                  <Route
                    path="/seller/product-variant"
                    element={<NullVariantProductList />}
                  />

                  {/*MultiStores*/}
                  <Route
                    path="/seller/multi-stores"
                    element={<MultiStores />}
                  />

                  {/*MultiUsers*/}
                  <Route path="/seller/multiUsers" element={<MultiUsers />} />
                  <Route
                    path="/seller/addMultiUsers/:mobile"
                    element={<AddMultiUsers />}
                  />

                  {/* Orders */}
                  <Route path="/seller/orders" element={<OrderList />} />
                  <Route
                    path="/seller/orders/:ondcOrderId"
                    element={<OrderDetails />}
                  />
                  <Route
                    path="/seller/orders/:ondcOrderId/cancel"
                    element={<OrderCancel />}
                  />
                  <Route path="/seller/shipments" element={<ShipmentsList />} />
                  <Route
                    path="/seller/bulk-invoice"
                    element={<BulkInvoice />}
                  />

                  {/* Offers */}
                  <Route path="/seller/offers" element={<OfferList />} />
                  <Route
                    path="/seller/offers/:offerMstId"
                    element={<OfferDetails />}
                  />
                  <Route path="/seller/addoffers" element={<AddOffer />} />
                  <Route path="/seller/itemBased" element={<ItemBased />} />
                  <Route path="/seller/freebie" element={<Freebie />} />

                  {/* Return Order */}
                  <Route path="/seller/returns" element={<ReturnOrderList />} />
                  <Route
                    path="/seller/returns/:ondcReturnId"
                    element={<ReturnOrderDetails />}
                  />

                  {/* Issues */}
                  <Route path="/seller/issues" element={<IssueList />} />
                  <Route
                    path="/seller/issues/:ondcIssueId"
                    element={<IssueDetails />}
                  />

                  {/* Profile */}
                  <Route path="/seller/profile" element={<SellerProfile />} />

                  {/* Contact US */}
                  <Route path="/seller/contact" element={<Contact />} />

                  {/* Customer */}
                  <Route path="/seller/customers" element={<CustomerList />} />

                  {/* Buyer */}
                  <Route path="/seller/buyers" element={<BuyerList />} />

                  {/* LSP */}
                  <Route
                    path="/seller/logistic-partners"
                    element={<LogisticPartnerList />}
                  />
                  <Route path="/seller/payments" element={<Payments />} />

                  {/* Downloads */}
                  <Route path="/seller/downloads" element={<Downloads />} />
                </Route>
              </Route>
            )}
            {!isLoggedIn && (
              <Route
                path="baas"
                element={<Baas setLocationPath={setLocationPath} />}
              >
                <Route path="/baas" element={<Navigate replace to="login" />} />
                <Route
                  path="login"
                  element={
                    <Login
                      locationPath={locationPath}
                      setLocationPath={setLocationPath}
                    />
                  }
                />
                <Route
                  path="registration"
                  element={
                    <Registration
                      locationPath={locationPath}
                      setLocationPath={setLocationPath}
                    />
                  }
                />
                <Route path="forget" element={<ForgetPassword />} />
                <Route path="otp" element={<Otp />} />
                <Route path="reset" element={<ResetPass />} />
                <Route path="ONDC" element={<LandingPage />} />
                <Route
                  path="ONDC"
                  element={
                    <Authentication
                      locationPath={locationPath}
                      setLocationPath={setLocationPath}
                    />
                  }
                >
                  <Route
                    path="registration"
                    element={
                      <OndcRegistration setLocationPath={setLocationPath} />
                    }
                  />
                  <Route
                    path="login"
                    element={<OndcLogin setLocationPath={setLocationPath} />}
                  />
                </Route>
              </Route>
            )}
            <Route path="*" element={<Navigate replace to={"/"} />} />
          </Routes>
          <ToastContainer className="custom-toast-container" />
        </Suspense>
      )}
    </>
  );
}
export default App;
