import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previousPath: null,
};

export const pathNameSlice = createSlice({
  name: "pathname",
  initialState,
  reducers: {
    setPreviousPath: (state, action) => {
      state.previousPath = action.payload;
    },
  },
});

export const { setPreviousPath } = pathNameSlice.actions;

export default pathNameSlice.reducer;
