import { useEffect, useRef } from "react";
import "./InProgressPopup.scss";

const InProgressPopup = ({ children, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <div className="popup-overlay">
      <div className="popup-content" ref={popupRef}>
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <div className="content-text">{children}</div>
      </div>
    </div>
  );
};

export default InProgressPopup;
