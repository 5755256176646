import { configureStore } from "@reduxjs/toolkit";
import sellerReducer from "../reducers/sellerSlice";
import pathnameReducer from "../reducers/pathNameSlice";

const store = configureStore({
  reducer: {
    seller: sellerReducer,
    pathname: pathnameReducer,
  },
});

export default store;
