import React, { useState } from "react";

const AuthContext = React.createContext(
  {
    token: "",
    isLoggedIn: false,
    login: (token) => {},
    logOut: () => {},
    canProcessOrder: false,
    processOrder: () => {},
  }
  // This is not needed. Its just used to understand data type of variables
);

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

export const AuthContextProvider = (props) => {
  // const initialToken = sessionStorage.getItem('newToken');
  const [token, setToken] = useState();
  const [userIsLoggedIn, setUserLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") === "true" ? true : false
  );
  const [canProcessOrder, setCanProcessOrder] = useState(
    sessionStorage.getItem("canProcessOrder") === "Y" ? "Y" : "N"
  );
  const [mobile, setMobile] = useState(sessionStorage.getItem("mobile") || "");
  const [viewProductList, setViewProductList] = useState([]);
  const [viewVarientList, setViewVarientList] = useState([]);
  const [shipmentsList, setShipmentsList] = useState([]);
  const [bulkInvoiceList, setBulkInvoiceList] = useState([]);
  const [sellerCode, setSellerCode] = useState(
    sessionStorage.getItem("sellerCode") || ""
  );
  const [authData, setAuthData] = useState(() => {
    try {
      const storedData = sessionStorage.getItem("authData");
      return storedData ? JSON.parse(storedData) : {};
    } catch (error) {
      // console.error("Failed to parse authData from sessionStorage:", error);
      return {};
    }
  });

  const logoutHandler = () => {
    // setToken(null);
    // sessionStorage.removeItem('newToken');
    setUserLoggedIn(false);
    sessionStorage.setItem("loggedIn", false);

    // sessionStorage.removeItem('cpId');
    // sessionStorage.removeItem('cpMstCode');
    // localStorage.removeItem('tokenValue');
    // const user = sessionStorage.getItem('userName');
    // localStorage.removeItem(user);
  };

  const warningLogoutHandler = () => {
    alert("Your session expired, Please login again");
    logoutHandler();
  };

  const loginHandler = (response, token, expirationTime) => {
    setUserLoggedIn(true);
    sessionStorage.setItem("loggedIn", true);
    // setToken(token);
    // sessionStorage.setItem('newToken', token);

    // const remainingTime = calculateRemainingTime(expirationTime);
    // setTimeout(warningLogoutHandler, remainingTime);
    const authData = response?.DATA;

    setAuthData(authData);
    sessionStorage.setItem("authData", JSON.stringify(authData));
  };

  const processOrder = (value) => {
    setCanProcessOrder("Y");
    sessionStorage.setItem("canProcessOrder", value);
  };

  const updateMobile = (newMobile) => {
    setMobile(newMobile);
    sessionStorage.setItem("mobile", newMobile);
  };

  const updateSellerCode = (newSellerCode) => {
    setSellerCode(newSellerCode);
    sessionStorage.setItem("sellerCode", newSellerCode);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logOut: logoutHandler,
    canProcessOrder: canProcessOrder,
    processOrder,
    mobile,
    sellerCode,
    setMobile: updateMobile,
    setSellerCode: updateSellerCode,
    viewProductList: viewProductList,
    setViewProductList: setViewProductList,
    viewVarientList: viewVarientList,
    setViewVarientList: setViewVarientList,
    shipmentsList: shipmentsList,
    setShipmentsList: setShipmentsList,
    bulkInvoiceList: bulkInvoiceList,
    setBulkInvoiceList: setBulkInvoiceList,
    authData: authData,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
