import apiAxios from "../../API/axios";

// url names

// const mainUrl = "http://bbps-uat-alb-2001131731.ap-south-1.elb.amazonaws.com";
// const mainUrl = "https://ecsuat.easypay.co.in";
const sendOtpUrl = "/wow/seller/registration/generateOtp";
const verifyMobileOtpUrl = "/wow/seller/registration/validateOtp";
const registrationUrl = "/wow/seller/registration/addSellerBasicProfile";
const loginUrl = "/wow/seller/registration/doSellerLogin";
const resetPasswordUrl = "/wow/seller/registration/resetPassword";
const uploadDocumentUrl = "/wow/seller/registration/uploadSellerDocument";
const sellerAddressUrl = "/wow/seller/registration/addSellerAddress";
const pincodeUrl = "/wow/seller/registration/getPincodeDetail";
const SellerDetailsUrl = "/wow/seller/registration/getSellerDetailByMobile";
const getIfscDetailsUrl = "/wow/seller/registration/getIfscDetail";
const saveBankDetailsUrl = "/wow/seller/registration/addSellerBankDetail";
const storeTimingUrl = "/wow/seller/registration/addStoreTiming";
const storeHolidayUrl = "/wow/seller/registration/addStoreHoliday";
const storeSettingUrl = "/wow/seller/registration/addStoreSetting";
const updateProfileUrl = "/wow/seller/registration/updateSellerBasicProfile";
const coreCategoryDetailUrl = "/wow/seller/registration/getCoreCategoryDetail";
const addBusinessProfileUrl =
  "/wow/seller/registration/addSellerBusinessProfile";
const addDeliveryChargesUrl = "/wow/seller/registration/setDeliveryCharge";

//product
const getCategoryUrl = "/wow/seller/inventory/getProductCategory";
const getProductDetailsUrl = "/wow/seller/inventory/getSellerProductDetail";
const getSubCategoryUrl = "/wow/seller/inventory/getProductSubCategory";
const getFieds = "/wow/seller/inventory/getProductAttributesBySubcategory";
const addProductUrl = "/wow/seller/inventory/addProductDetail";
const addProductImageUrl = "/wow/seller/inventory/addProductImage";

//manageMultiStore
const getMultiStoresUrl = "/wow/seller/registration/getMultiStoreDetails";
const disableMappedStore = "/wow/seller/registration/disableMappedStore";

const getPartnerProfile = "/wow/seller/registration/partner/getPartnerProfile";

export async function sendOtp(payload) {
  try {
    const response = await apiAxios.post(sendOtpUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function verifyMobileOtp(payload) {
  try {
    const response = await apiAxios.post(verifyMobileOtpUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function registrationForm(payload) {
  try {
    const response = await apiAxios.post(registrationUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function LoginForm(payload) {
  try {
    const response = await apiAxios.post(loginUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ResetPasssword(payload) {
  try {
    const response = await apiAxios.post(resetPasswordUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function UploadDocument(payload) {
  try {
    const response = await apiAxios.post(uploadDocumentUrl, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function pincodeDetails(payload) {
  try {
    const response = await apiAxios.post(pincodeUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function sellerAddress(payload) {
  try {
    const response = await apiAxios.post(sellerAddressUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function sellerDetailsByMobile(payload) {
  try {
    const response = await apiAxios.post(SellerDetailsUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function ifscDetails(payload) {
  try {
    const response = await apiAxios.post(getIfscDetailsUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function saveSellerBankDetails(payload) {
  try {
    const response = await apiAxios.post(saveBankDetailsUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function saveSellerStoreTime(payload) {
  try {
    const response = await apiAxios.post(storeTimingUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function saveSellerHoliday(payload) {
  try {
    const response = await apiAxios.post(storeHolidayUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function saveStoreSetting(payload) {
  try {
    const response = await apiAxios.post(storeSettingUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateSellerProfile(payload) {
  try {
    const response = await apiAxios.post(updateProfileUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function coreCategoryDetail(payload) {
  try {
    const response = await apiAxios.post(coreCategoryDetailUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function addBusinessProfile(payload) {
  try {
    const response = await apiAxios.post(addBusinessProfileUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function addDeliveryCharge(payload) {
  try {
    const response = await apiAxios.post(addDeliveryChargesUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

//category
export async function getCategory(payload) {
  try {
    const response = await apiAxios.post(getCategoryUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getProductDetails(payload) {
  try {
    const response = await apiAxios.post(getProductDetailsUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function apiCall(apiUrl, payload) {
  try {
    const response = await apiAxios.post(apiUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function subCategoryUrl(payload) {
  try {
    const response = await apiAxios.post(getSubCategoryUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function fieldsUrl(payload) {
  try {
    const response = await apiAxios.post(getFieds, payload);
    return response;
  } catch (error) {
    return error;
  }
}

// export async function addProduct(payload) {
//   try {
//     const response = await apiAxios.post(addProductUrl, payload);
//     return response;
//   } catch (error) {
//     return error;
//   }
// }

export async function addProductImage(payload) {
  try {
    const response = await apiAxios.post(addProductImageUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

//multiStore
export async function getMultiStores(payload) {
  try {
    const response = await apiAxios.post(getMultiStoresUrl, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPartnerProfileDetail(payload) {
  try {
    const response = await apiAxios.post(getPartnerProfile, payload);
    return response;
  } catch (error) {
    return error;
  }
}

export async function unmapStore(payload) {
  try {
    const response = await apiAxios.post(disableMappedStore, payload);
    return response;
  } catch (error) {
    return error;
  }
}
