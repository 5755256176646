import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./OndcRegistration.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registrationForm, sendOtp, verifyMobileOtp } from "../../../Services/UserService";
import showPass from "../../../../assets/images/icons/show-pass.png"
import hidePass from "../../../../assets/images/icons/hide-pass.png"
import Spinner from "../../../Common/Loading/Spinner";
import { partnerDetails } from "../../../../util/utils";

function RegistrationOndc({setLocationPath}) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  let domainDetail = partnerDetails()
  const location = useLocation()
  const navigate = useNavigate();
  const Ref = useRef(null);
  const toastId = React.useRef(null);
  console.log("toastId",toastId);
  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [business, setBusiness] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpBtn, setOtpBtn] = useState("Send OTP");
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [otpInputDisable, setOtpInputDisable] = useState(false);
  const [otpInputVisible, setOtpInputVisible] = useState(true)
  const [timer, setTimer] = useState("");
  const [timerOn, setTimerOn] = useState(false);
  const [resendOtpClose, setResendOtpClose] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mobileFiledDisable, setMobileFiledDisable] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPassError, setConfirmPassError] = useState(false);
  const [confirmPassMsg, setConfirmPassMsg] = useState("");
  const [emailError, setemailError] = useState(false);
  const [emailErrorMsg, setemailErrorMsg] = useState("");
  

  // show hide password
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // mobile regex
  const mobileRegex = /^\d{10}$/;

  // OTP regex
  const otpRegex = /^\d{4,6}$/;

  // // Validate email
  // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


  useEffect(() => {
    if(error){
      if (email.length > 0 && !emailRegex.test(email)) {
        setemailError(true);
        setemailErrorMsg("Please Enter Valid Email");
      } else if (email.length > 0 && emailRegex.test(email)) {
        setemailError(false);
        setemailErrorMsg("");
      }
    }
  }, [email]);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    // let { total, hours, minutes, seconds } = getTimeRemaining(e);
    setTimerOn(true);
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        // (hours > 9 ? hours : '0' + hours) + ':' +
        // (minutes > 9 ? minutes : '0' + minutes) + ':'
        // +
        seconds > 9 ? seconds : "0" + seconds
      );
    } else {
      setTimerOn(false);
    }
  };

  const clearTimer = (e) => {
    setTimer("60");
    if (Ref.current) clearInterval(Ref.current);
    setOtpInputDisable(true);
    setVerifyBtn(true);
    setOtpBtn("Resend OTP");
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const sendUserOtp = async (e) => {
    e.preventDefault();
    if (mobile.length === 10) {
      setLoading(true);
      const info = {
        HEADER: {
          mobile: mobile,
        },
        DATA: {
          eventCode: "SELLER_REGISTRATION_OTP_MESSAGE",
          host: window.location.href
        },
      };
      await sendOtp(info)
        .then((response) => {
          console.log("response: ", response);
          if (response.status === 200) {
            setLoading(false);
            const formResponse = response.data;
            if (formResponse.RESP_CODE === 300) {
              onClickReset();
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(formResponse.RESP_MSG);
              }
            } else {
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(formResponse.RESP_MSG);
              }
              setLoading(false)
            }
          } else {
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(
                "Server issue, please try again later"
              );
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setMobileError(true);
      setLoading(false);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (otp.length >= 4) {
      setLoading(true);
      const info = {
        HEADER: {
          mobile: mobile,
        },
        DATA: { 
          otp: otp,
          eventCode: "SELLER_REGISTRATION_OTP_MESSAGE",
          host: window.location.href
        },
      };

      await verifyMobileOtp(info)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setOtpInputVisible(false)
            const formResponse = response.data;
            if (formResponse.RESP_CODE === 300) {
              setOtpInputDisable(false);
              setResendOtpClose(false);
              setMobileFiledDisable(false);
              setVerifyBtn(false);
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(formResponse.RESP_MSG);
              }
            } else {
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(formResponse.RESP_MSG);
              }
              setLoading(false);
            }
          } else {
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.error(
                "Server issue, please try again later"
              );
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      setOtpError(true);
      setLoading(false);
    }
  };

  const submitData = async (e) => {

    e.preventDefault();
    console.log("data", fName, mName, lName, mobile, email, password);
    setError(true);
    setMobileError(true);
    setOtpError(true);

    if (email.length == 0) {
      setemailError(true);
      setemailErrorMsg("Email address is required");
    }

    if (email.length > 0 && !emailRegex.test(email)) {
      setemailError(true);
      setemailErrorMsg("Please Enter Valid Email");
    } else if (email.length > 0 && emailRegex.test(email)) {
      setemailError(false);
      setemailErrorMsg("");
    }

    if (
      mobile.length !== 0 &&
      email.length !== 0 &&
      emailRegex.test(email) &&
      business.length !== 0 &&
      password.length !== 0 &&
      otp.length !== 0
    ) 
    {
      const info = {
        HEADER: {
          mobile: mobile,
        },
        DATA: {
          email: email,
      password: password,
      businessName: business,
        }
      };

      //resendOtpClose check OTP verify or not.
      if (resendOtpClose === false) {
        await registrationForm(info)
          .then((response) => {
            console.log("response: ", response);
            if (response.status === 200) {
              setLoading(false);
              const formResponse = response.data;
              if (formResponse.RESP_CODE === 300) {
                if (!toast.isActive(toastId.current)) {
                  toastId.current = toast.success(formResponse.RESP_MSG);
                }
                const data = {
                  username: formResponse.DATA.userName,
                };
                const queryString = new URLSearchParams(data).toString();
                navigate(`/baas/login?${queryString}`);
              } else {
                if (!toast.isActive(toastId.current)) {
                  toastId.current = toast.error(formResponse.RESP_MSG);
                }
                setLoading(false);
              }
            } else {
              if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                  "Server issue, please try again later"
                );
              }
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Please Verify mobile number");
        }
      }
    }
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error("Copy & Paste is disabled");
    }
  };
  return (
    <Fragment>
       {loading ? <Spinner /> : ""}
      <div id="singup-box" className="col-12 col-sm-12 col-md-6">
      <form className="ondc-register-form" id="registration-form">
        <h4>Welcome to {domainDetail?.partnerName}</h4>
        <h5 className="main-title">Create your account to start selling</h5>
        <div className="input">
          <div className="inputBox">
            <label>Mobile Number*</label>
            <input
              type="text"
              onChange={(e) => setMobile(e.target.value)}
              onKeyDown={(e) => {
                const allowedKeys = ["Backspace", "Delete"];
                const isNumeric = /^[0-9]$/.test(e.key);
                const isAllowedKey = allowedKeys.includes(e.key);

                if (!isNumeric && !isAllowedKey) {
                  e.preventDefault();
                }
              }}
              disabled={!mobileFiledDisable}
              maxLength={10}
              id="mobile"
              autoComplete="off"
            />
            {mobileError && !mobileRegex.test(mobile) ? (
              <span className="error">Mobile number is required</span>
            ) : (
              <span className="error hide-error">
                Mobile number is required
              </span>
            )}
          </div>
          {
                otpInputVisible &&  
          <div>
         
            <div className="input-group">
             
              <input
                className="form-control rounded-1"
                maxLength={6}
                onChange={(e) => setOtp(e.target.value)}
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "Delete"];
                  const isNumeric = /^[0-9]$/.test(e.key);
                  const isAllowedKey = allowedKeys.includes(e.key);

                  if (!isNumeric && !isAllowedKey) {
                    e.preventDefault();
                  }
                }}
                disabled={!otpInputDisable}
              />
           
              {resendOtpClose ? (
                <div>
                  {verifyBtn ? (
                    <button
                      className="btn btn-submit rounded-1 ms-2"
                      onClick={verifyOtp}
                    >
                      Verify
                    </button>
                  ) : (
                    ""
                  )}
                  {timer ? (
                    <button
                      className={`btn btn-submit rounded-1 ms-2 ${
                        timerOn ? "field-disable" : ""
                      }`}
                      onClick={sendUserOtp}
                      disabled={timerOn}
                    >
                      {timerOn ? `${otpBtn} (${timer}s)` : `${otpBtn}`}
                    </button>
                  ) : (
                    <button
                      className="btn btn-submit rounded-1 ms-2 signup-otp-btn"
                      onClick={sendUserOtp}
                    >
                      {otpBtn}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
             
            {otpError && !otpRegex.test(otp) ? (
              <span className="error">OTP is required</span>
            ) : (
              <span className="error hide-error">Please enter valid otp</span>
            )
            }
          </div>
          }
          <div className="inputBox">
            <label>Email Address*</label>
            <input
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              autoComplete="off"
            />
            {/* {error && !emailRegex.test(email) ? (
              <span className="error">Please Enter Valid Email</span>
            ) : (
              <span className="error hide-error">Please Enter Valid Email</span>
            )} */}
            {emailError ? (
              <span className="error">{emailErrorMsg}</span>
            ) : (
              <span className="error hide-error">Please Enter Valid Email</span>
            )}
          </div>

          <div className="inputBox">
            <label>Business Name*</label>
            <input
              type="business"
              onChange={(e) => setBusiness(e.target.value)}
              id="business"
              autoComplete="off"
            />
            {error && business.length <= 0 ? (
              <span className="error">Business name is required</span>
            ) : (
              <span className="error hide-error">
                Please enter business name
              </span>
            )}
          </div>

          <div className="inputBox register-pass">
            <label>Password*</label>
            <input
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              onCopy={(e) => preventCopyPaste(e)}
              onPaste={(e) => preventCopyPaste(e)}
              onCut={(e) => preventCopyPaste(e)}
              id="pwd"
              autoComplete="off"
            />
            <span onClick={togglePasswordVisibility} className="password-toggle">
              {showPassword ?
              <img src={showPass} alt="show-pass"/> : <img src={hidePass} alt="hide-pass"/> 
              } 
      </span>
            {error && password.length <= 0 ? (
              <span className="error">Password is required</span>
            ) : (
              <span className="error hide-error">Please Enter Password</span>
            )}
          </div>
            {/* {error && confirmPassword !== password ? (
              <span className="error">
                Password and Confirm Password do not match.
              </span>
            ) : (
              <span className="error hide-error">
                Password and Confirm Password do not match.
              </span>
            )} */}

          {/* <div className="inputBox">
            <label>Confirm Password*</label>
            <input
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onCopy={(e) => preventCopyPaste(e)}
              onPaste={(e) => preventCopyPaste(e)}
              onCut={(e) => preventCopyPaste(e)}
              id="cpwd"
              autoComplete="off"
            />
          
            {confirmPassError ? (
              <span className="error">{confirmPassMsg}</span>
            ) : (
              ""
            )}
          </div> */}
          <div className="inputBox">
            <button type="submit" className="my-btn" onClick={submitData}>
              SIGN UP
            </button>
          </div>
        </div>
        <p className="forget">
          Have an account? <Link to="/baas/ONDC/login" onClick={()=>{setLocationPath("/baas/ONDC/login")}}>Login to your account</Link>
        </p>
      </form>
      </div>
      <ToastContainer/>
    </Fragment>
  );
}

export default RegistrationOndc;
