import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Spinner from "../components/Common/Loading/Spinner";
import { setPreviousPath } from "../Store/reducers/pathNameSlice";

const PrivateRoute = () => {
  const sellerData = useSelector((state) => state.seller.sellerData);
  const loading = useSelector((state) => state.seller.loading);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setPreviousPath(location.pathname));
  }, [location.pathname, dispatch]);

  if (loading) {
    return <Spinner />;
  }

  if (sellerData?.sellerDedupe?.processOrderFlag !== "Y") {
    return <Navigate to="/seller/kyc" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
