import React, { useRef } from "react";
import { MdClose, MdQrCodeScanner } from "react-icons/md";
import { QRCodeCanvas } from "qrcode.react";
import { Button, Modal } from "rsuite";
import { IoCloudDownloadOutline } from "react-icons/io5";
import logo from "../../../assets/images/easypaylogoblack.png";
import ondcLogo from "../../../assets/images/ONDC_logo.png";
import OKLogo from "../../../assets/images/logo OK.png";
import html2canvas from "html2canvas";
import "./QrModal.scss";
import jsPDF from "jspdf";

const QrModal = ({ isVisible, onClose, sellerData }) => {
  const qrCardRef = useRef();

  const downloadAsPdf = async () => {
    const qrCardElement = qrCardRef.current;

    const canvas = await html2canvas(qrCardElement, {
      scale: 3,
      backgroundColor: "#ffffff",
      useCORS: true,
    });

    const originalWidth = canvas.width;
    const originalHeight = canvas.height;
    const scaledWidth = originalWidth / 3;
    const scaledHeight = originalHeight / 3;

    const pageWidth = 595.28;
    const pageHeight = 841.89;

    const xPosition = (pageWidth - scaledWidth) / 2;
    const yPosition = (pageHeight - scaledHeight) / 2;

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [pageWidth, pageHeight],
    });

    pdf.addImage(
      imgData,
      "PNG",
      xPosition,
      yPosition,
      scaledWidth,
      scaledHeight
    );
    const qrLink = sellerData?.storeSetting?.sellerStoreLink;
    if (qrLink) {
      const qrX = xPosition;
      const qrY = yPosition;
      const qrWidth = scaledWidth;
      const qrHeight = scaledHeight;

      pdf.link(qrX, qrY, qrWidth, qrHeight, { url: qrLink });
    }
    //text and styling
    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 255);
    const textX = xPosition + scaledWidth / 2 - 40;
    const textY = yPosition + scaledHeight + 30;
    pdf.text("Click To Open Store", textX, textY);

    pdf.setDrawColor(0, 0, 255);
    pdf.setLineWidth(1);

    //make underline
    const underlineStartX = textX;
    const underlineEndX = textX + 82;
    const underlineY = textY + 2;
    pdf.line(underlineStartX, underlineY, underlineEndX, underlineY);

    if (qrLink) {
      pdf.link(textX, textY - 10, 120, 10, { url: qrLink });
    }
    pdf.save(`${sellerData?.storeName || "catalog"}.pdf`);
  };

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      keyboard={false}
      className="qr-modal"
    >
      <div className="close-button" onClick={onClose}>
        <MdClose />
      </div>
      <Modal.Header>
        <div className="qr-modal-header">
          <div className="qr-title-container">
            <MdQrCodeScanner className="qr-icon" />
            <Modal.Title className="qr-title">
              Download Your Store QR
            </Modal.Title>
          </div>
          <Button
            appearance="primary"
            className="download-button"
            onClick={downloadAsPdf}
            disabled={!sellerData?.storeSetting?.sellerStoreLink}
          >
            <IoCloudDownloadOutline />
            Download
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        {sellerData?.storeSetting?.sellerStoreLink ? (
          <div className="qr-card" ref={qrCardRef}>
            <div className="qr-primary-text">{sellerData?.storeName}</div>
            <div className="qr-secondary-text">
              Scan QR code to view this catalog
            </div>
            <div className="qr-code-container">
              <a
                href={sellerData?.storeSetting?.sellerStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="qr-link"
              >
                <QRCodeCanvas
                  value={sellerData?.storeSetting?.sellerStoreLink}
                  size={180}
                  level="H"
                  imageSettings={{
                    src: OKLogo,
                    height: 70,
                    width: 70,
                    excavate: true,
                  }}
                />
                <span className="qr-hover-text">Scan or click on this QR</span>
              </a>
            </div>
            <div className="qr-footer">
              <span>Catalog Powered By</span>
              <div className="qr-footer-logos">
                <img src={logo} alt="Easy Pay" />
                <img src={ondcLogo} alt="ONDC" />
              </div>
            </div>
          </div>
        ) : (
          <div className="not-qr-text">
            Your store QR not generated. Please contact the support team for the
            same.
          </div>
        )}
        {sellerData?.storeSetting?.sellerStoreLink && (
          <div className="qr-share-text">
            Download and share this with your customers to order online
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default QrModal;
