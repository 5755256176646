import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Sidenav.scss";
import dashboard64 from "../../../assets/images/icons/dashboard/dashboard64.png";
import payin64 from "../../../assets/images/icons/pay-in/payin64.png";
import payout64 from "../../../assets/images/icons/pay-out/payout64.png";
import order from "../../../assets/images/icons/pay-out/Orders.png";
import products from "../../../assets/images/icons/pay-out/Products.png";
import payments from "../../../assets/images/icons/pay-out/Payments.png";
import settings from "../../../assets/images/icons/pay-out/Settings.png";
import isuesAndGrievance from "../../../assets/images/icons/pay-out/issue grievance.png";
import marketing from "../../../assets/images/icons/pay-out/Marketing.png";
import helpAndSupport from "../../../assets/images/icons/pay-out/Help & Support.png";
import reportsAndMis from "../../../assets/images/icons/report/report_mis.png";

import { NavLink, Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";

// import { useDedupCall } from "../../Common/CustomeHooks/useDedupCall";

import userLogo from "../../../assets/images/easypaylogoblack.png";
import useKycDedupe from "../../Common/CustomeHooks/useKycDedupe";
import AuthContext from "../../../Store/auth-context";
import InProgressPopup from "../../Common/InProgressPopup/InProgressPopup";
import { RiMenu2Line } from "react-icons/ri";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
// import Spinner from "../../Common/Loading/Spinner";

const Sidenav = () => {
  // let userLogo = sessionStorage.getItem("partnerLogo");
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const { dedupCall, loader } = useKycDedupe();
  // const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const [logo, setLogo] = useState(userLogo);
  const [logo2, setLogo2] = useState(userLogo);
  const [permissionArr, setPermissionArr] = useState([]);
  const [permissionFieldsArr, setPermissionFieldsArr] = useState([]);
  const popupRef = useRef(null);
  const [isMaximized, setIsMaximized] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  const addIconsOnlyClass = () => {
    const list = document.body.classList;
    list.toggle("sidenav-icon-only");
    setIsMaximized(!isMaximized);
  };

  // useEffect(() => {
  //   let submenus = document.getElementsByClassName("sub-menu-content");
  //   // submenus?.[0]?.classList?.add("show");
  // }, []);

  const openSubmenu = (e) => {
    e.currentTarget.nextSibling.classList.toggle("show");
  };

  // Close all sidenav submenu when clicked outside
  // window.addEventListener("click", (event) => {
  //   let sidenav = document.getElementById("sidenav");
  //   if (sidenav) {
  //     if (!event.target.closest(".nav-area")) {
  //       navArea();
  //     }
  //     if (!event.target.closest(".right-nav-toggler")) {
  //       sideNavArea(sidenav, event);
  //     }
  //   }
  // });

  const navArea = () => {
    let submenus = document.getElementsByClassName("sub-menu-content");
    for (const element of submenus) {
      let openSubmenu = element;
      if (openSubmenu.classList.contains("show")) {
        openSubmenu.classList.remove("show");
      }
    }
  };

  const sideNavArea = (sidenav, event) => {
    if (!event.target.closest(".open-submenu-btn")) {
      if (sidenav.classList.contains("right-nav")) {
        sidenav.classList.remove("right-nav");
      }
    }
  };

  // const handleNavLinkClick = (event) => {
  //   if (!event.currentTarget.getAttribute("to")) {
  //     event.preventDefault();
  //     // Handle the click event for the NavLink with empty 'to' prop
  //     // Add your custom logic here
  //   }
  // };

  const handleNavLinkClick = (event) => {
    const toAttr = event.currentTarget.getAttribute("to");
    if (!toAttr || toAttr.trim() === "#") {
      event.preventDefault();
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const sellerDetails = async () => {
    // dedupCall();
  };

  useEffect(() => {
    const partnerLogo = sessionStorage.getItem("partnerLogo");
    const partnerLogo2 = sessionStorage.getItem("partnerLogo2");
    const loginUserPermission = sessionStorage.getItem("loginUserPermission");
    const loginUserPermissionArr = sessionStorage.getItem(
      "loginUserPermissionFields"
    );
    setLogo(partnerLogo || userLogo);
    setLogo2(partnerLogo2 || userLogo);
    setPermissionArr(loginUserPermission || []);
    setPermissionFieldsArr(loginUserPermissionArr || []);
  }, [userLogo]);

  return (
    <Fragment>
      <nav id="sidenav">
        <div className="sidenav-brand-wrapper fixed-top d-none d-lg-flex justify-content-center align-items-center sidenav-mini">
          <Link to="/">
            <div className="brand-logo">
              <img height="50" src={logo} alt="logo" />
            </div>
            <div className="brand-logo-mini">
              <img height="28" src={logo2} alt="logo" />
            </div>
          </Link>
        </div>
        <ul className="navbar-nav nav-area">
          <li className="nav-item">
            <NavLink to="/seller/dashboard" className="nav-link">
              <span className="menu-icon">
                <img className="menu-img" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">Dashboard</span>
            </NavLink>
          </li>

          {(permissionFieldsArr.includes("My Orders") ||
            permissionFieldsArr.includes("Shipments") ||
            permissionFieldsArr.includes("Returns") ||
            permissionFieldsArr.includes("Bulk Invoice")) && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={order} alt="" />
                </span>
                <span className="menu-title">
                  Orders
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  {permissionFieldsArr.includes("My Orders") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/orders" className="sub-nav-link">
                        My Orders
                      </NavLink>
                    </li>
                  )}
                  {/* {authCtx.canProcessOrder === "N" && (
                  <li className="sub-nav-item">
                    <NavLink
                      to="/seller/kyc"
                      className="sub-nav-link"
                      // onClick={sellerDetails}
                    >
                      ONDC Registration
                    </NavLink>
                  </li>
                )} */}

                  {permissionFieldsArr.includes("Shipments") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/shipments" className="sub-nav-link">
                        Shipments
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("Returns") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/returns" className="sub-nav-link">
                        Returns
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("Bulk Invoice") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/bulk-invoice"
                        className="sub-nav-link"
                      >
                        Bulk Invoice
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {(permissionFieldsArr.includes("My Products") ||
            permissionFieldsArr.includes("Add New") ||
            permissionFieldsArr.includes("Variant")) && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={products} alt="" />
                </span>
                <span className="menu-title">
                  Products
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  {permissionFieldsArr.includes("My Products") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/product-list"
                        className="sub-nav-link"
                      >
                        My Products
                      </NavLink>
                    </li>
                  )}

                  {permissionFieldsArr.includes("Add New") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/add-product"
                        className="sub-nav-link"
                      >
                        Add New
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("Variant") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/product-variant"
                        className="sub-nav-link"
                      >
                        Variant
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {permissionFieldsArr.includes("Payments & Settlement") && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={payments} alt="" />
                </span>
                <span className="menu-title">
                  Payments
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  <li className="sub-nav-item">
                    <NavLink to="/seller/payments" className="sub-nav-link">
                      Payments & Settlement
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}

          {(permissionFieldsArr.includes("Buyer Activation") ||
            permissionFieldsArr.includes("LSP Activation") ||
            permissionFieldsArr.includes("Manage Store") ||
            permissionFieldsArr.includes("Manage User")) && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={settings} alt="" />
                </span>
                <span className="menu-title">
                  Settings
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  {permissionFieldsArr.includes("Buyer Activation") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/buyers" className="sub-nav-link">
                        Buyer Activation
                      </NavLink>
                    </li>
                  )}

                  {permissionFieldsArr.includes("LSP Activation") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/logistic-partners"
                        className="sub-nav-link"
                      >
                        LSP Activation
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("Manage Store") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/multi-stores"
                        className="sub-nav-link"
                      >
                        Manage Store
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("Manage User") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to="/seller/multiUsers"
                        className="sub-nav-link"
                        // onClick={handleNavLinkClick}
                      >
                        Manage User
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {permissionFieldsArr.includes("Issues") && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={isuesAndGrievance} alt="" />
                </span>
                <span className="menu-title">
                  Issue & Grievance
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  <li className="sub-nav-item">
                    <NavLink to="/seller/issues" className="sub-nav-link">
                      Issues
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {(permissionFieldsArr.includes("Offers") ||
            permissionFieldsArr.includes("Store QR") ||
            permissionFieldsArr.includes("My Customers")) && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={marketing} alt="" />
                </span>
                <span className="menu-title">
                  Marketing
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  {permissionFieldsArr.includes("Offers") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/offers" className="sub-nav-link">
                        Offers
                      </NavLink>
                    </li>
                  )}

                  {permissionFieldsArr.includes("Store QR") && (
                    <li className="sub-nav-item">
                      <NavLink
                        to=""
                        className="sub-nav-link"
                        onClick={handleNavLinkClick}
                      >
                        Store QR
                      </NavLink>
                    </li>
                  )}
                  {permissionFieldsArr.includes("My Customers") && (
                    <li className="sub-nav-item">
                      <NavLink to="/seller/customers" className="sub-nav-link">
                        My Customers
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          <li className="nav-item">
            <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
              <span className="menu-icon">
                <img className="menu-img" src={helpAndSupport} alt="" />
              </span>
              <span className="menu-title">
                Help & Support
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div className="sub-menu-content">
              <ul className="navbar-nav sub-menu">
                {permissionFieldsArr.includes("Profile") && (
                  <li className="sub-nav-item">
                    <NavLink to="/seller/profile" className="sub-nav-link">
                      Profile
                    </NavLink>
                  </li>
                )}

                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    FAQ
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink
                    to=""
                    className="sub-nav-link"
                    onClick={handleNavLinkClick}
                  >
                    Help
                  </NavLink>
                </li>
                <li className="sub-nav-item">
                  <NavLink to="/seller/contact" className="sub-nav-link">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          {permissionFieldsArr.includes("Manage Download") && (
            <li className="nav-item">
              <div className="nav-link open-submenu-btn" onClick={openSubmenu}>
                <span className="menu-icon">
                  <img className="menu-img" src={reportsAndMis} alt="" />
                </span>
                <span className="menu-title">
                  Reports & MIS
                  <span id="down-arrow" className="arrow">
                    <IoIosArrowDown />
                  </span>
                </span>
              </div>
              <div className="sub-menu-content">
                <ul className="navbar-nav sub-menu">
                  <li className="sub-nav-item">
                    <NavLink to="/seller/downloads" className="sub-nav-link">
                      Downloads
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
          <li className="nav-item">
            <div
              className="nav-link open-submenu-btn"
              onClick={addIconsOnlyClass}
            >
              <span className="menu-icon">
                <button className="navbar-toggler">
                  {isMaximized ? (
                    <BiChevronsRight className="expand-arrow" />
                  ) : (
                    <BiChevronsLeft className="compress-arrow" />
                  )}
                </button>
              </span>
              <span className="menu-title">
                {isMaximized ? "Expand" : "Collapse"}
              </span>
            </div>
          </li>
          {/* <div className="compress-menu">
            <button className="navbar-toggler" onClick={addIconsOnlyClass}>
              <div className="arrow-container">
                {isMaximized ? (
                  <BiChevronsRight className="expand-arrow" />
                ) : (
                  <BiChevronsLeft className="compress-arrow" />
                )}
                <span className="compress-tool">
                  {isMaximized ? "Expand" : "Collapse"}
                </span>
              </div>
            </button>
          </div> */}
        </ul>
      </nav>
      {showPopup && (
        <InProgressPopup onClose={closePopup}>
          <p>This feature is available soon. Please check back later!</p>
        </InProgressPopup>
      )}
    </Fragment>
  );
};

export default Sidenav;
