export const displayDate = (date) => {
  let dt = new Date(date).toString();
  let dt1 = dt.slice(8, 10);
  let month = dt.slice(4, 7);
  let year = dt.slice(11, 15);

  let resultDate = dt1 + " " + month + ", " + year;

  return resultDate;
};

export const amountFormater = (amt) => {
  return `\u20B9${
    !!amt
      ? amt?.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")
      : "0"
  }`;
};

export const convertDate = (dateFromBack) => {
  // Convert to date Object 
  const dateAndTime = dateFromBack;
  const [date, time] = dateAndTime.split(" "); 
  const [dayNumber, month, year] = date.split("-");
  const dateObj = new Date(`${year}-${month}-${dayNumber}`);
  const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = dateObj.getDay();
  return week[day];
}

export const toTitleCase = (str = "") => {
  if (!str) return "";
  const words = str.split(" ");
  const lowerCaseWords = words.map(word => {
    const lowerCaseStr = word.toLowerCase();
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
  })
  return lowerCaseWords.join(" ");
  
}
// Validations for decimal numbers or numbers etc remaining

export const partnerDetails = () => {
  let part = sessionStorage.getItem("partnerDetails")
  if (part && Object.entries(part).length) {
    return JSON.parse(part)
  } else {
    let obj = {
      partnerLogoPath: "https://prodondcdoc.easypay.co.in/partner_logo/easypay_logo.png",
      partnerName: "Easy Pay",
      partnerFaviconLogoPath: "https://prodondcdoc.easypay.co.in/partner_logo/easypay_favicon.ico"
    }
    sessionStorage.setItem("partnerDetails", JSON.stringify(obj))
    return obj
  }
};

export const getCurrentMonthStart = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1);
};
  
export const getToday = () => {
  return new Date();
};